import React, { useState } from 'react';
import CaseStudyPage from './CaseStudyPage';

const PasswordProtectedPage = () => {
  const [password, setPassword] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const correctPassword = '1234'; // Replace with your desired password

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsAuthorized(true);
    } else {
      alert('Incorrect password, please try again.');
    }
  };

  if (isAuthorized) {
    return <CaseStudyPage />; // Replace with your protected content
  }

  return (
    <div className='flex flex-col items-center justify-center grow '>
      <div className='max-w-md w-full md:bg-white p-4 md:p-8 md:rounded-md md:shadow-xl'>
        <div className='flex flex-col items-center text-center space-y-4'>

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-8">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
          </svg>

          <h2 className='text-lg md:text-2xl font-bold text-gray-800'>Protected Area</h2>
          <p className='text-gray-600 whitespace-pre-wrap text-sm md:text-base'>
            <span className='inline-block  whitespace-nowrap'>This is a protected area.</span>{" "}
            <span className='inline-block whitespace-nowrap'>Please enter your password:</span>
          </p>
          <form onSubmit={handleSubmit} className='flex items-center'>
            <input
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className='p-2 border rounded-l-md border-r-0 mb-0'
              placeholder='Password'
            />
            <button type='submit' className='primary-button rounded-r-md !rounded-l-none border-l-0'>
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordProtectedPage;
