import React from 'react';

const CaseStudyPage = () => {
  return (
    <div className="px-4 md:px-16 py-8 md:py-16">
      {/* Header Section */}
      <section className="text-center mb-12">
        <h1 className="text-3xl md:text-5xl font-bold mb-4">
          Augmenting the everyday listening experience
        </h1>
        <p className="text-base md:text-lg text-gray-600">
          Customized headphone subscription
        </p>
      </section>

      {/* Overview Section */}
      <section className="mb-12">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4">Overview</h2>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8">
          <div className="md:w-1/3">
            <h3 className="font-semibold">Goal</h3>
            <p className="text-gray-600">
              Create a customized headphone service that enhances daily listening experiences by tailoring the sound output based on the user's environment and personal preferences.
            </p>
          </div>
          <div className="md:w-1/3">
            <h3 className="font-semibold">Duration</h3>
            <p className="text-gray-600">6 months</p>
          </div>
          <div className="md:w-1/3">
            <h3 className="font-semibold">Role</h3>
            <p className="text-gray-600">UX Designer, Researcher</p>
          </div>
        </div>
      </section>

      {/* Research Section */}
      <section className="mb-12">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4">Research</h2>
        <p className="text-gray-600 mb-4">
          We started with in-depth research to understand user needs, preferences, and pain points when using headphones. This involved interviews, surveys, and user journey mapping.
        </p>
        <img src="research-image-url" alt="Research Process" className="w-full rounded-md shadow-md mb-4" />
      </section>

      {/* Insights Section */}
      <section className="mb-12">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4">Insights</h2>
        <p className="text-gray-600 mb-4">
          Through our research, we identified the following key insights:
        </p>
        <ul className="list-disc list-inside text-gray-600">
          <li>Users want headphones that adapt to their environment.</li>
          <li>Sound quality and personalization are top priorities.</li>
          <li>Battery life is a crucial factor for daily use.</li>
        </ul>
      </section>

      {/* Service Concepts Section */}
      <section className="mb-12">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4">Service Concepts</h2>
        <p className="text-gray-600 mb-4">
          Based on the insights, we developed the following service concepts:
        </p>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8">
          <div className="md:w-1/2">
            <img src="concept-image-1-url" alt="Concept 1" className="w-full rounded-md shadow-md mb-4" />
            <p className="text-gray-600">A customizable platform to enhance user flexibility and expand the usage of headphones.</p>
          </div>
          <div className="md:w-1/2">
            <img src="concept-image-2-url" alt="Concept 2" className="w-full rounded-md shadow-md mb-4" />
            <p className="text-gray-600">AI-driven sound personalization features that adjust audio based on user preferences and context.</p>
          </div>
        </div>
      </section>

      {/* Key Learnings Section */}
      <section className="mb-12">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4">Key Learnings</h2>
        <p className="text-gray-600">
          The importance of flexible and adaptive design was a key takeaway. Users highly value solutions that provide both customization and ease of use.
        </p>
      </section>

      {/* Footer */}
      <footer className="text-center py-8">
        <p className="text-gray-600">Feel free to reach out at <a href="mailto:contact@example.com" className="text-indigo-600">contact@example.com</a></p>
      </footer>
    </div>
  );
};

export default CaseStudyPage;
