import { Link } from "react-router-dom"

const valueProps = [
    {
        headline: "Service Design",
        subheadline: "Transforming touchpoints into cohesive experiences through research, strategy, and implementation.",
        ctaLabel: "Learn more",
        img: process.env.PUBLIC_URL + "/valueprops/workshop.JPG"
    },
    {
        headline: "UX Design & Research",
        subheadline: "Applying user research and design to create seamless, engaging, and effective digital experiences.",
        ctaLabel: "Learn more",
        img: process.env.PUBLIC_URL + "/valueprops/ux.png"
    },
    {
        headline: "Generative AI & Tech",
        subheadline: "Combining tech expertise with strategic foresight and rapid prototyping to craft future-ready experiences.",
        ctaLabel: "Learn more",
        img: process.env.PUBLIC_URL + "/valueprops/tech.jpg"
    },
]

const ValueProp = ({ headline, subheadline, ctaLabel, img }: { headline: string, subheadline: string, ctaLabel: string, img: string }) => {
    return (
        <div className='flex flex-col md:flex-row items-center space-x-0 md:space-x-8 space-y-4 md:space-y-0 max-w-screen-lg'>
            <div className='grow md:w-1/2'>
                <img src={img} alt="Value Prop Image" className='rounded-md aspect-video object-cover' />
            </div>
            <div className='grow md:w-1/2 flex flex-col space-y-2 md:space-y-4 text-center md:text-left'>
                <p className='text-xl md:text-2xl font-bold'>{headline}</p>
                <p className='text-sm md:text-base'>{subheadline}</p>

                <Link to="/password-protected">
                    <button className="py-2 text-primary text-sm font-semibold inline-flex items-center">
                        {ctaLabel}
                        <span className="ml-2">→</span>
                    </button>
                </Link>
            </div>
        </div>
    )
}

export const ValueProps = () => {
    return (
        <>
            {
                valueProps.map(vp => <ValueProp headline={vp.headline} subheadline={vp.subheadline} ctaLabel={vp.ctaLabel} img={vp.img} />)
            }
        </>

    )
}