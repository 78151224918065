import { Route, Routes } from 'react-router-dom';
import PasswordProtectedPage from './pages/PasswordProtectedPage';
import HomePage from './pages/HomePage';
import { NavBar } from './components/NavBar';

// img example: <img src={logo} className="App-logo" alt="logo" />
// css: //@import '@radix-ui/themes/styles.css';

function App() {
  return (
    <div className=' bg-gradient-to-t from-indigo-100 to-slate-50 min-h-screen pb-20 flex flex-col items-center space-y-8 md:space-y-16'>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/password-protected" element={<PasswordProtectedPage />} />
      </Routes>
    </div>
  );
}

export default App;
